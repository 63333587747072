
import Vue, { PropType } from "vue";

import MyEditor from "@/components/lock/MyEditor.vue";
import { Building, Lock } from "@/interfaces";
import http from "@/http";

export interface DataType {
  lock?: Lock;
}

export default Vue.extend({
  components: {
    MyEditor
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    }
  },
  data(): DataType {
    return {
      lock: {
        priority: 3,
        type: "その他",
        can_install: false,
        confirmed_at: undefined,
        note: "",
        building_id: this.building.id
      }
    };
  },
  methods: {
    async save(e: Lock) {
      const url = `buildings/${this.building.id}/locks`;

      await http.post(url, {
        ...e
      });

      await this.$router.push(`/building/${this.building.id}/lock`);
    }
  }
});
